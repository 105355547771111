import { Link } from "@StarberryUtils";
import React from "react";
import { Container, Col, Row} from "react-bootstrap";
import { GetURL } from "../../common/site/functions";
import './SellProduct.scss';
const SellProduct = (props) => {
    let url = ''
    if(props.link && props.link._id){
        url = GetURL(props.link._id)
    } else {
        url = "book-a-valuation"
    }

    // console.log("zzzzzz", props.title)

    return (
        <React.Fragment>
            <section className={`sell-product ${props.class}`}>
                <Container>
                    <Row>
                        <Col className="p-0">
                         <div className="sell-info position-relative">
                            <div className="sell-info-boxes mr-4">
                            <h3 className="w-100">{props.title}</h3>
                            {props.desc && <p className="w-100 pt-2">{props.desc}</p>}
                            </div>
                            <Link to={`/${url}`} className="btn btn-primary">
                                {props.label ? props.label : 'Book a Valuation'}
                            </Link>
                         </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
};
export default SellProduct