import React, { useState } from "react";
import { Container, Tabs, Tab } from 'react-bootstrap';
import Slider from "react-slick";
import ScrollAnimation from 'react-animate-on-scroll';
import './Properties.scss'
import { GetPropertiesListByStatus } from "../../../queries/common-use-query";
import { Link } from "@StarberryUtils";
import Loader from "../../../components/loader";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const titleVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const contentVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        delay: 0.4,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const PropertiesListTab = (props) => {
    const settings = {
        dots: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,

                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,

                },
            },
            {
                breakpoint: 377,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,

                },
            },
        ],
    }

    const [key, setKey] = useState('sale');

    const place = props.place;

    const type = key == "sale" ? "For Sale" : "To Let";

    const { data, loading, error } = GetPropertiesListByStatus(place, type);

    // if(!data || (data && data.properties.length == 0)) {
    //     return null
    // }

    return (
        <InView threshold={0.2}>
            {({ ref, inView }) => {
                return (
                    <motion.section className="property"
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >
                        <Container>
                            <span class="sub-content">{props.sub_title ? props.sub_title : 'properties'}</span>
                            <ScrollAnimation animateIn='fadeInUp' animateOnce>
                                <motion.div variants={titleVariants}>
                                    <h3>{props.title ? props.title : 'Find your next home'}</h3>
                                </motion.div>

                                <motion.div variants={contentVariants}>
                                    <Tabs
                                        id="controlled-tab-example"
                                        activeKey={key}
                                        onSelect={(k) => setKey(k)}
                                        className="area-properties-tab"
                                    >
                                        <Tab eventKey="sale" title={`Property for Sale in ${props.place}`}>
                                            {(data && data.properties) && <Slider className={`slick-arrow ${data.properties.length <= 2 ? "similar-single-properties" : ""}`} {...settings}>
                                                {data.properties.filter(c => c.search_type === "sales").map((property, i) => {
                                                    let processedImages = JSON.stringify({});
                                                    if (property?.imagetransforms?.images_Transforms) {
                                                        processedImages = property.imagetransforms.images_Transforms;
                                                    }
                                                    console.log(processedImages, "IMMMMM")
                                                    return (
                                                        <div className="slider-wrap" key={i}>
                                                            <Link to={`/property-${property.search_type === "sales" ? "for-sale" : "to-rent"}/${property.slug}-${property._id}`} className="w-100">
                                                                <div class="slider position-relative w-100">
                                                                    <div className="home-img w-100">
                                                                        <div class="slider-bg-img w-100">
                                                                            {property.images.length > 0 &&
                                                                                <ImageTransform
                                                                                    imagename="property.images.featured"
                                                                                    attr={{ alt: '', class: '' }}
                                                                                    imagesources={property.images[0].url}
                                                                                    renderer="srcSet"
                                                                                    imagetransformresult={processedImages}
                                                                                    id={property._id}
                                                                                />
                                                                            }
                                                                        </div>
                                                                        <div className="home-content">
                                                                            <h4>{property.display_address}</h4>
                                                                            <span className="price">{property.price_qualifier} £{property.price.toLocaleString()}</span>
                                                                        </div>
                                                                    </div>
                                                                    <button className="prop-card-btn">{property.status}</button>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )
                                                }
                                                )}
                                                {window.innerWidth > 1024 && <div className="slider-wrap"></div>}
                                            </Slider>}
                                            {loading && <Loader />}
                                            {(data && data.properties && data.properties.filter(c => c.search_type === "sales").length == 0) && <h4 className="text-center my-5">No results found</h4>}
                                        </Tab>
                                        <Tab eventKey="let" title={`Property to Rent in ${props.place}`}>
                                            {(data && data.properties) && <Slider className={`slick-arrow ${data.properties.length <= 2 ? "similar-single-properties" : ""}`} {...settings}>
                                                {data.properties.filter(c => c.search_type === "lettings").map((property, i) => {
                                                    let processedImages = JSON.stringify({});
                                                    if (property?.imagetransforms?.images_Transforms) {
                                                        processedImages = property.imagetransforms.images_Transforms;
                                                    }
                                                    return (
                                                        <div className="slider-wrap" key={i}>
                                                            <Link to={`/property-${property.search_type === "sales" ? "for-sale" : "to-rent"}/${property.slug}-${property._id}`} className="w-100">
                                                                <div class="slider position-relative w-100">
                                                                    <div className="home-img w-100">
                                                                        <div class="slider-bg-img w-100">
                                                                            {property.images.length > 0 &&
                                                                                <ImageTransform
                                                                                    imagename="property.images.featured"
                                                                                    attr={{ alt: '', class: '' }}
                                                                                    imagesources={property.images[0].url}
                                                                                    renderer="srcSet"
                                                                                    imagetransformresult={processedImages}
                                                                                    id={property._id}
                                                                                />
                                                                            }
                                                                        </div>
                                                                        <div className="home-content">
                                                                            <h4>{property.display_address}</h4>
                                                                            <span className="price">{property.price_qualifier} £{property.price.toLocaleString()}</span>
                                                                        </div>
                                                                    </div>
                                                                    <button className="prop-card-btn">{property.status}</button>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )
                                                }
                                                )}
                                                {window.innerWidth > 1024 && <div className="slider-wrap"></div>}
                                            </Slider>}
                                            {loading && <Loader />}
                                            {(data && data.properties && data.properties.filter(c => c.search_type === "lettings").length == 0) && <h4 className="text-center my-5">No results found</h4>}
                                        </Tab>
                                    </Tabs>
                                </motion.div>
                            </ScrollAnimation>
                        </Container>
                    </motion.section>
                )
            }}
        </InView>
    )
};

export default PropertiesListTab