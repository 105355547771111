import * as React from "react"
import { graphql } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/grunticon.scss";
import "../scss/bootstrap-overrides.scss";
import "../scss/global.scss";
import "../scss/custom.scss";
import { Header } from "../components/Header/Header";
import { Footer } from "../components/Footer/Footer";
import '../components/Static/AboutPage/About.scss';
import IntroCard from "../components/Static/IntroCard/IntroCard"
import Content from "../components/Static/StaticContent/AreaGuideContent"
import Map from '../components/map/office-details-map'
import Gallery from "../components/Home/Gallery/Gallery";
import PropertiesList from "../components/Home/Properties/PropertiesTab"
import Service from "../components/Home/Service/Service";
import SEO from "../components/seo";


const AreaTemplate = (props) => {
    const GQLPage = props.data.glstrapi?.areaGuide;
    const GLServiceConent = GQLPage?.Content.filter(module => typeof module.Service_Title !== "undefined" && module.Service_Title != null);
    const GLServiceImageOnly = GQLPage?.Content.filter(module => module.Service_Title == null && module.Service_Title == null && module.Service_Image != null);
    return (
        <div className="wrapper wrapper-static">
            <SEO
                title={`${GQLPage.Title} Area Guide`}
                description={`Read here and get to Know more about ${GQLPage.Title} living and the property, Contact Bovingdons team today.`}
            />
            {/* <h1 className="d-none">{GQLPage.Title} Area Guide</h1> */}
            <Header/>
                <div className="area-guide-details">
                    <IntroCard content={GQLPage.Intro_Content} tag="areaguide" title={GQLPage.Title} location={props.location} />

                    <Content data={GQLPage} GLServiceImageOnly={GLServiceImageOnly}/>

                    <div className={GQLPage.Title}>
                        <Service data={GLServiceConent} class=""/>
                    </div>

                    {GQLPage.Latitude ? <Map lat={GQLPage.Latitude} log={GQLPage.Longtitude} Title={GQLPage.Title}/> : '' }

                    {GQLPage.Post_Code && <PropertiesList place={GQLPage.Title} postcode={GQLPage.Post_Code}/>}

                    <Gallery />
                </div>
            <Footer search="Popular_Search_Static"/>
        </div>
    )
}

export default AreaTemplate

export const pageQuery = graphql`
    query AreaQuery($id: ID!) {
        glstrapi {
            areaGuide(id: $id) {
                  Banner_Title
                  Banner_Image {
                    alternativeText
                    url
                  }
                  id
                  _id
                  Embed_Video_URL
                  Intro_Content
                  Latitude
                  Longtitude
                  Meta_Description
                  Meta_Title
                  Title
                  URL
                  Post_Code
                  imagetransforms
                  Content {
                    ... on GLSTRAPI_ComponentModulesBookValuationCtaBlock {
                      id
                      CTA_Block_Label
                      CTA_Block_Title
                      CTA_Block_Description
                      CTA_Block_Link {
                        _id
                        URL
                      }
                    }
                    ... on GLSTRAPI_ComponentModulesContent {
                      id
                      Add_Static_Content
                    }
                    ... on GLSTRAPI_ComponentModulesAddServiceBlock {
                        id
                        Service_CTA_Label
                        Service_CTA_Link {
                            _id
                        }
                        Service_Title
                        Service_Content
                        Service_Image {
                            alternativeText
                            url
                            url_sharp {
                                childImageSharp {
                                    fluid(quality: 100, maxWidth: 1200, maxHeight:700) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`