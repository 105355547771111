import React from 'react';
import logo from '../images/logo.png'
const Loader = () => {
    return (
        <div className="loader">
            <img src={logo}/>
            <p>Loading...</p>
        </div>
    )
}

export default Loader;