import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import HTMLReactParser from 'html-react-parser';
import PlayVideo from "../../Play/custom-video";
import ScrollAnimation from 'react-animate-on-scroll';
import CTABlock from "../../SearchResult/SellProduct/SellProduct";
import YouTube from 'react-youtube';
import closeIcon from "../../../images/close.png";

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const titleVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const videoRef = React.createRef(null);

const AreaGuideContent = (props) => {
    const [showTour, setVirtualTour] = React.useState(false);
    const [virtualTour, setVirtualTourUrl] = React.useState("");

    const getVideoId = (url) => {
        if (url.split("v=").length > 1) {
            var video_id = url.split('v=')[1];
            var ampersandPosition = video_id.indexOf('&');
            if (ampersandPosition != -1) {
                video_id = video_id.substring(0, ampersandPosition);
            }
            return video_id
        } else if (url.split("embed/").length > 0) {
            return url.split("embed/")[1]
        } else {
            return url.split("youtu.be/")[1]
        }
    }

    const playVideo = () => {
        var elem = videoRef.current;
        const fullScreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled;
        if (fullScreenAvailable) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        } else {
            elem.classList.add("fullscreen-video")
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'formClick',
            'formType': 'form-video',
            'formId': 'local-living-video',
            'formName': "Local Living Video",
            'formLabel': window.location.href
        });
    }

    const closeVideo = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
        setVirtualTourUrl("");
        setVirtualTour(false);
        videoRef.current.classList.remove("fullscreen-video");
    }

    let processedImages = JSON.stringify({});
    if (props.data.imagetransforms?.Banner_Image_Transforms) {
        processedImages = props.data.imagetransforms.Banner_Image_Transforms;
    }

    function fullScreenHandler() {
        var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
        if (fullscreenElement == null) {
            setVirtualTourUrl("");
            setVirtualTour(false);
        }
    }

    React.useEffect(() => {
        document.addEventListener("fullscreenchange", fullScreenHandler, false);
        document.addEventListener("webkitfullscreenchange", fullScreenHandler, false);
        document.addEventListener("MSFullscreenChange", fullScreenHandler, false);
        document.addEventListener("mozfullscreenchange", fullScreenHandler, false);
    }, []);
    return (
        <div className="area-guide-content">
            <Container>
                <Row>
                    {props.data.Banner_Image &&
                        <div className="content">
                            <ScrollAnimation animateIn='fadeInUp' className="product-card-img static-banner img-zoom gallery-image" animateOnce>
                                {props.data.Banner_Image &&
                                    <ImageTransform
                                        imagesources={props.data.Banner_Image.url}
                                        renderer="srcSet"
                                        imagename="area-guides.Banner_Image.banner"
                                        attr={{ alt: props.data.Banner_Image.alternativeText, className: '' }}
                                        imagetransformresult={processedImages}
                                        id={props.data.id}
                                    />
                                }
                                {/* <picture>
                                    <source media="(min-width:1200px)" srcSet={props.data.Banner_Image.url} />
                                    <source media="(min-width:768px)" srcSet={props.data.Banner_Image.url} />
                                    <img src={props.data.Banner_Image.url} alt={props.data.Banner_Image.alternativeText} />
                                </picture> */}

                                {props.data.Embed_Video_URL &&
                                    <div className="product-btn">
                                        <strong className="video-btn">
                                            <i className="icon-video" onClick={async e => {
                                                await setVirtualTourUrl(props.data.Embed_Video_URL);
                                                await setVirtualTour(true);
                                                playVideo();
                                            }} />
                                            {/* <PlayVideo url={props.data.Embed_Video_URL}/> */}
                                        </strong>
                                    </div>
                                }
                            </ScrollAnimation>
                        </div>
                    }
                </Row>
            </Container>

            {(props.GLServiceImageOnly && props.GLServiceImageOnly.length > 0) &&
                <div className="d-flex flex-row justify-content-center area-guide-img my-2">
                    <img src={props.GLServiceImageOnly[0].Service_Image?.url} className="mx-auto" />
                </div>
            }
            {props.data && props.data.Content.map((module, i) => {
                return (
                    <InView threshold={0.2}>
                        {({ ref, inView }) => {
                            return (
                                <motion.div className="page-full-content"
                                    ref={ref}
                                    initial="hidden"
                                    animate={inView ? "visible" : "hidden"}
                                    variants={containerVariants}
                                >
                                    {module.Add_Static_Content &&
                                        <Container>
                                            <Row>
                                                <motion.div variants={titleVariants}>
                                                    <div className={`plain-content ${i === 0 ? 'first' : ''}`}>
                                                        {HTMLReactParser(module.Add_Static_Content.replace(/\n/g, '<br />'))}
                                                    </div>
                                                </motion.div>
                                            </Row>
                                        </Container>
                                    }
                                    {module.CTA_Block_Title &&
                                        <CTABlock title={module.CTA_Block_Title} label={module.CTA_Block_Label} link={module.CTA_Block_Link} class="val-cta-block" />
                                    }
                                </motion.div>
                            )
                        }}
                    </InView>
                )
            })}
            <div ref={videoRef}>
                {(virtualTour && showTour) &&
                    <div>
                        <YouTube
                            className="video-inline"
                            videoId={getVideoId(virtualTour)}
                            opts={{
                                height: "100%",
                                width: "100%",
                                playerVars: {
                                    autoplay: 1,
                                    disablekb: 1
                                }
                            }}
                            onEnd={closeVideo}
                            onError={(err) => {
                                console.log(err)
                                closeVideo()
                            }}
                            isAutoPlay={1}
                        />
                        <div className="btn btn-light close-icon" onClick={closeVideo}>
                            <img src={closeIcon} alt="close-icon" />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
};

export default AreaGuideContent